@import url(https://use.fontawesome.com/releases/v5.0.6/css/all.css);
/*------------------------------------------------------------------ 
    IMPORT FILES 
-------------------------------------------------------------------*/

/*------------------------------------------------------------------ 
    SKELETON
-------------------------------------------------------------------*/
/*navbar--------------------------------------------------------------------------------------*/

.brand-goph {
  margin-left: 5%;
}

.navbar-nav {
  justify-content: center;
}

#navbarNavDropdown2 {
  justify-content: center;
}

.item-adm {
  color: #fff;
}

.togglerBtn {
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.nav-logo{
  width: 15%;
}

#ph-name {
  font-size: 17px;
  color: white;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}

.nav-username {
  position: absolute;
  width: 15%;
}

.far-icon{
  margin: 4px;
}

.rojo{
  color: red;
}

.card-size3{
  height: 106px;
  width: 106px;
}

.icon-btn-user{
  margin-top: 4px;
}

#logo-header{
  width: 12%;
}

.nav-header{
  vertical-align: middle;
}

.card-goph{
  width: 17rem;
}

.icon-menu-home{
  height: 30%;
  width: 30%;
  padding: 5px;
}

.home-card{
  padding: 20px 80px;
}

.purple{
  background-color: #6f42c1;
}

.pink{
  background-color: #d63384;
}

.container123{
  padding: 0 140px;
  background-color: #f8f9fa;
}

.row-header {
  padding-top: 20px;
}

.total-page{
  height: 110vh;
}

.Active{
  border-bottom: 2px solid white !important;
}

.Active2{
  border-bottom: 2px solid gray !important;
}

.titulo{
  font-family: 'Enriqueta', arial, serif;
  line-height: 1.25;
  margin: 0 0 10px;
  font-size: 32px;
  color: #333;
  font-weight: bold;
}

.titulo1{
  font-family: 'poppins', arial;
  font-size: 22px;
  color: #333;
  font-weight: bold;
}

.texto-descriptivo{
  font-family: 'Enriqueta', arial, serif;
  line-height: 1.25;
  margin: 0 0 10px;
  font-size: 14px;
  color: #333;
}

.cursiva-negrilla{
  font-style: italic;
  font-weight: bold;
}

.data-label{
  margin: 5px !important;
}

.c-10{
  padding: 0 12% !important;
}

.sub-page{
  height: 100vh;
  background-color: #f8f9fa;
}

.interline1{
  line-height: 1;
}

.fa-blue{
  color: #007bff;
}

.mt-05{
  margin-top: 5;
  margin-bottom: 0;
}

.mb-05{
  margin-bottom: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.fixed-top{
  z-index:100;
}

.titulo .tituloUploader{
  font-size: 24px;
}



/*--------------------------------------------MENU ADMIN--------------------------------------------*/

.cardAdm {
  padding: 20px;
  border: none;
  text-align: center;
}

.contCard {
  padding: 40px 0;
}

.container12 {
  text-align: center;
  margin-top: 1.5em;
}

.container1 a {
  text-decoration: none;
  -webkit-text-decoration-color: initial;
          text-decoration-color: initial;
  margin: 0em;
}

.btnNBcc {
  display: inline-flex;
  margin-bottom: 0;
  margin-top: 2px;
  color: #cccccc;
  font-size: 15px;
  font-weight: 400;
}
.btnNBcc i {
  float: left;
  padding-top: 0.5vmin;
  padding-right: 0.5vmin;
}

p.btnNBcc {
  float: left;
  width: 8vmax;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.countAdm {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background:  	#dc3545;
  position: absolute;
  top: 3%;
  margin-left: 60%;
}

.centered {
  position: absolute;
  font-weight: bold;
  font-size: 0.8rem;
  color: #f8f9fa;
  left: 0;
  text-align: center;
  top: 5px;
  width: 100%
}

/* .MuiPaper-root {
  margin-bottom: 50px;
} */

.BtnForm-back {
  padding-top: 5vmax;
  margin-left: 15vmax;
}

.iconoMenuHome {
  transition: 0.3s;
  height: 150px;
  width: 150px;
  position: relative;
}

.iconoMenuHome:hover {
  transform: scale(1.1);
}

.donita{
  height: 85vh !important;
  margin-top: 90px;
}

.spinner-border{
  width: 4rem;
  height: 4rem;
  border: 0.30em solid currentColor;
}

.tooltipHome{
   width: 100%;
}

.MuiTableRow-footer{
  overflow: auto !important;
}

/*------------------------------------------------------------------------------------------------------------

                                  RESPONSIVE

--------------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 2580px) and (min-width: 1440px) {
  .cardAdm2 {
    height: 300px;
    margin-left: 9vmin;
    margin-right: 9vmin;
    margin-bottom: 2vmax;
  }

  .item-adm {
    display: none;
  }

  .contCard {
    height: 85vmax;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1366px) {
  .cardAdm2 {
    font-size: 2.5vmin;
    height: 15vmax;
    margin-left: 15vmin;
    margin-bottom: 1vmax;
  }
  .item-adm {
    display: none;
  }

  .container1 {
    margin-top: 2vmax;
  }

  .contCard {
    height: 175vmin;
  }

  .btnNBcc {
    width: 11vmin;
  }
}

@media screen and (max-width: 1365px) and (min-width: 1024px) {
  .cardAdm2 {
    font-size: 2.5vmin;
    height: 15vmax;
    margin-left: 15vmin;
    margin-bottom: 1vmax;
  }

  .item-adm {
    display: none;
  }

  .container1 {
    margin-top: 2vmax;
  }

  .contCard {
    height: 175vmin;
  }

  .btnNBcc {
    width: 11vmin;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 61%;
  }
}

@media screen and (max-width: 1199px) and (min-width: 300px) {
  .navbarBtnAdm2 {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) and (min-width: 620px) {
  .cardAdm2 {
    font-size: 2.5vmin;
    height: 15vmax;
    margin-left: 11vmin;
    margin-bottom: 1vmax;
  }


  .btnNBcc {
    margin-top: 5px;
    width: max-content !important;
  }
  .contCard {
    height: 110vmax;
  }

  .container1 {
    margin-top: 1vmax;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 64%;
  }
}

/* @media screen and (max-width: 719px) and (min-width: 540px) {
  
  .cardAdm {
    margin: 0 30px;
  }
} */

@media screen and (max-width: 619px) and (min-width: 441px) {
  .cardAdm2 {
    font-size: 0.9rem;
    height: 15vmax;
    margin-left: 11vmin;
    margin-bottom: 1vmax;
  }

  .btnNBcc {
    margin-top: 0px;
    width: max-content !important;
    font-size: 0;
  }

  .btnNBcc::after {
    font-family: "Font Awesome 5 Free";
    font-size: 1.8vmax;
    content: "\f007";
    text-align: left;
  }

  .container1 {
    margin-top: 2vmax;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 63%;
  }
}

@media screen and (max-width: 440px) and (min-width: 300px) {
  .cardAdm2 {
    font-size: 0.8rem;
    height: 120px;
    margin-left: 8vmin;
    margin-bottom: 2vmax;
  }
  
  #ph-name {
    display: none;
  }

  .btnNBcc {
    font-size: 0;
    width: max-content !important;
  }

  .btnNBcc::after {
    font-family: "Font Awesome 5 Free";
    font-size: 1.8vmax;
    content: "\f007";
    text-align: left;
  }

  .total-page{
    height: 120vh !important;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 67%;
  }
}

@media (max-width: 300px) {
  .cardAdm2 {
    font-size: 0.7rem;
    height: 120px;
    margin-bottom: 2vmax;
  }

  .btnNBcc {
    font-size: 0;
  }

  .btnNBcc::after {
    font-family: "Font Awesome 5 Free";
    font-size: 1.8vmax;
    content: "\f007";
    text-align: left;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 61%;
  }

  .total-page{
    height: 120vh !important;
  }

}

.spinnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    width: 56px;
    height: 56px;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: #299fff;
    animation: tri-spinner 1s infinite linear;
  }
  
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: tri-spinner 2s infinite;
  }
  
  .spinner::after {
    margin: 8px;
    animation-duration: 3s;
  }
  
  @keyframes tri-spinner {
    100% {
      transform: rotate(1turn);
    }
  }
  
  .loader {
    color: #4a4a4a;
    font-family: "Poppins",sans-serif;
    font-weight: 500;
    font-size: 25px;
    box-sizing: content-box;
    height: 40px;
    padding: 10px 10px;
    display: flex;
    border-radius: 8px;
  }
  
  .words {
    overflow: hidden;
  }
  
  .word {
    display: block;
    height: 100%;
    padding-left: 6px;
    color: #299fff;
    animation: cycle-words 5s infinite;
  }
  
  @keyframes cycle-words {
    10% {
      transform: translateY(-105%);
    }
  
    25% {
      transform: translateY(-100%);
    }
  
    35% {
      transform: translateY(-205%);
    }
  
    50% {
      transform: translateY(-200%);
    }
  
    60% {
      transform: translateY(-305%);
    }
  
    75% {
      transform: translateY(-300%);
    }
  
    85% {
      transform: translateY(-405%);
    }
  
    100% {
      transform: translateY(-400%);
    }
  }
  

  .dot-spinner {
    --uib-size: 2.3rem;
    --uib-speed: .9s;
    --uib-color: #abc9e8;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
  }
  
  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }
  
  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse0112 {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
  
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
* {
    box-sizing: border-box;
}

body {
    font-family: 'Source Sans 3', sans-serif;
}

.sidebar-nh {
    border-right: 1px solid #e9ecf2;
    box-shadow: 1px 0px #f8f9fa;
    height:100%;
    background-color: #f9f9f9 !important;
}

.sidebar-nh a {
    padding: 10px 10px 10px 20px;
    text-decoration: none;
    font-size: 18px;
    color: #333;
    display: block;
}

.sidebar-nh p {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.sidebar-nh a:hover {
    background-color: rgba(224, 229, 247, 0.7);
    border-radius: 10px;
    margin-right: 10px;
}

.main-content {
    margin-left: -15px !important;
}

.card-custom {
    margin-bottom: 10px;
    line-height: 0;
}

.card-custom.sol{
    min-height: 125px;
    max-height: 125px;
    border-radius: 12px;
    background-color: rgba(224, 229, 247, 0.7);
    color: #fff;
}

.card-custom.menu{
    min-height: 135px;
    max-height: 135px;
    overflow: hidden;
    background-color: white;
    border-radius: 12px;
}

.card-custom.menu i{
    font-size: 24px;
}

.card-custom.img{
    background-color: transparent;
    border: none;
}

.card-title{
    font-weight:lighter;
}

.card-menu-text{
    font-weight: 400;
    color:#787474;
    font-size: 15px;
}

.min-size-sol{
    padding: 7px;
    font-size: 18px;
    border-radius: 12px;
    background-color: rgba(224, 229, 247, 0.7);
    color: #fff;
}

.navbar-custom {
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
}

.navbar-custom .navbar-brand, .navbar-custom .nav-link {
    color: #333;
}

h1.title {
    font-family: "Arial";
    font-size: 20px;
}

.texto-resp {
    font-size: calc(0.8em + 0.2vw);
    line-height: 1.1em
}

.welcome{
    min-height: 200px;
    max-height: 200px;
    border-radius: 14px;
}

.text-content {
    margin-left: 12px;
    line-height: 1.2;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
    font-size: 16px;
}

.footer {
    background-color:#212529;
    padding: 20px 0;
    color:#ced4da;
}

.icon-text-container {
    display: flex;
    align-items: center;
}
.icon-circle {
    display: flex;
    margin-left: 50px;
}

.bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}


@media (max-width: 767.98px) {

    .main-content {
        min-width: calc(100% - 30px);
        margin-left: 15px !important;
    }

    .texto-resp {
        font-size: 1em;
    }

    .card-menu-text {
        padding-top: 5px;
    }

    .text-left-footer {
        text-align: center;
        margin-bottom: 1rem;
    }
}
.textFloow_cover__o35o2{
    text-overflow: ellipsis;
}

.textFloow_cover__o35o2:hover{
    text-overflow: unset;
}

.textFloow_titulo__3mrBY{
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.textFloow_titulo__3mrBY:hover{
    overflow: unset;
    animation: textFloow_slideIn__RgaCE 20s linear;
    -webkit-animation: textFloow_slideIn__RgaCE 20s linear;
}

@keyframes textFloow_slideIn__RgaCE{
    from {right: 0px;}
    to {right: 400px;}
}

.textFloow_txt_orange__1iW2P{
    color: chocolate !important;
}
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.tituloSize1{
        font-size: 1rem !important;
        font-weight: 500 !important;
        font-family: 'Poppins', sans-serif;
}

.tipoLetra{
    font-family: 'Poppins', sans-serif !important;
}

.titulo{
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.titulo:hover{
    overflow: unset;
    animation: slideIn 20s linear;
    -webkit-animation: slideIn 20s linear;
}

@keyframes slideIn{
    from {right: 0px;}
    to {right: 400px;}
} */


a{
    text-decoration: none !important;
}

a:active{
    text-decoration: none !important;
    border: none !important;
}

body{
	width: 100%;
    /*background-image: radial-gradient(circle at 43.84% 120.44%, #1093c6 0, #23688d 25%, #203f54 50%, #131a21 75%, #000000 100%) !important;*/
}

.contenedorPadre{
    margin-top: 5%;
    margin-bottom: 5%;
}

.contenedorPadre2 {
    /* width: 100%; */
    /* display: flex; */
    justify-content: space-evenly;
    align-items: center;
    /* flex-wrap: nowrap; */
    /* flex-direction: row; */
}

.ro

.contenedorPadreTabla{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 3vh;
}

.contenedorUploader{
    background-color: #E9EEF2;
    padding: 3% 0 3% 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.contAccionesUploader{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #8C8C7D;
    border-radius: 20px;
    padding: 6%;
    width: 75%;
    height: 100%;
    margin-bottom: 3%;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
}

.contAccionesUploader:hover{
    background-color: #8c8c7d9f;
    border: 4px dashed #8C8C7D;
}

.contMensajeUploader{
    background-color:#8C8C7D;
    width: 50%;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    color: #E9EEF2;
}

#drop-area{
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.spanO{
    font-weight: bold;
    font-size: 1.5vw;
    padding-bottom: 10px;
    margin-bottom: 8px;
}

.contenedorInfoDoc{
    overflow: hidden;
}

/* .contenedorInfoDoc span, p{
    font-weight: bold !important;
} */

.contenedorInfoDoc span{
    color: #0A5DA6;
    font-size: 1.2vw;
}

.tituloDoc{
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tituloDoc:hover{
    overflow: unset;
    animation: slidein2 20s linear !important;
}

@keyframes slidein2{
    from {right: 0px;}
    to {right: 800px;}
}

.botonRevisarArch{
    display:flex !important;
    height: 5vh !important;
    font-size: large !important;
    white-space: nowrap !important;
}

.LinkBRevisarA{
    margin-right: 10% !important;
}

.bRAIcon{
    margin-left: 5px;
    padding-bottom: 2.5px;
}

/*--------------------------------------- TABLA ARCHIVOS ---------------------------------------------*/

.contenedorTablaArch{
    background-color: #E9EEF2;
    width: 100%;
    margin: auto;
    border-radius: 5px !important;
}

.tituloTablaArch{
    text-align:center;
    color: #E9EEF2;
    font-size: xx-large;
}

.table-dark-arch{
    background-color: #8C8C7D;
}

.cBElminar{
    width:100% !important;
}

.botonVolverUpl{
    display:flex !important;
    margin-right: 2% !important;
    height: 5vh !important;
    font-size: large !important;
    white-space: nowrap !important;
}

.bRAIcon2{
    margin-right: 5px;
}

/*----------------------------------------------------------------------------------------------------

                                            RESPONSIVE

-----------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 2580px) and (min-width: 1440px) {


}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
    .botonVolverUpl{
        margin-left: 2% !important;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .contenedorPadre2{
        flex-direction: column !important;
    }
    
    .contenedorUploader{
        margin-bottom: 2% !important;
    }

    .botonVolverUpl{
        margin-top: 2% !important;
    }

}

@media screen and (max-width: 768px) and (min-width: 440px) {

    .botonRevisarArch{
        display:flex !important;
        margin-right: 0 !important;
    }

    .contenedorUploader{
        margin-top: 10% !important;
        margin-bottom: 12%;
    }

    .contenedorInfoDoc span{
        color: #0A5DA6;
        font-size: 2.2vw;
    }

    .contAccionesUploader{
        padding: 0;
        padding-top: 1%;
        border: none !important;
        background-color: transparent !important;
    }

    .contAccionesUploader h3, .spanO, #drop-area{
        display: none;
    }

}

@media screen and (max-width: 440px) and (min-width: 320px) {

    .botonRevisarArch{
        display:flex !important;
        margin-right: 0 !important;
    }

    .contenedorUploader{
        margin-top: 20% !important;
        margin-bottom: 18%;
    }

    .contenedorInfoDoc span{
        color: #0A5DA6;
        font-size: 5vw;
    }

    .contAccionesUploader{
        padding: 0;
        padding-top: 1%;
        border: none !important;
        background-color: transparent !important;
    }

    .contAccionesUploader h3, .spanO, #drop-area{
        display: none;
    }

    .botonVolverUpl{
        position: sticky !important;
    }

}

@media (max-width: 320px) {

    .contenedorPadre2{
        flex-direction: column !important;
        overflow-x: auto;
        padding-left: 155vw;
        padding-right: 155vw !important;
    }

    .botonRevisarArch{
        display:flex !important;
        margin-right: 0 !important;
    }

    .contenedorUploader{
        margin-top: 20% !important;
        margin-bottom: 18%;
    }

    .contenedorInfoDoc span{
        color: #0A5DA6;
        font-size: 5vw;
    }

    .contAccionesUploader{
        padding: 0;
        padding-top: 1%;
        border: none !important;
        background-color: transparent !important;
    }

    .contAccionesUploader h3, .spanO, #drop-area{
        display: none;
    }
    .contenedorPadreTabla{
        margin-left: 60vw;
        margin-right: 68vw;
    }
}

.containerlooper{
/*background-color: lightgray;*/
    display: flex;

    margin-bottom: 50px;
}

.gridCard{
    float: left;
    margin-bottom: 5%;
}

.cardReservas{
    margin: 2vmax !important;
    border-radius: 10px !important;
    box-shadow: 13px 12px 12px -2px rgba(0,0,0,0.38);
}

.cardReservas ::-webkit-scrollbar{
    display: none;
}

.tituloACardZ{
    width: 100%;
    height: 38px;
    margin-bottom: 0.4vmax !important;
    overflow-x: hidden;
    
}

.contImgTarjetaCarr{
    background-image: radial-gradient(circle at 43.84% 120.44%, #1093c6 0, #23688d 25%, #203f54 50%, #131a21 75%, #000000 100%);
}

.iconCardReserva{
    width: 50% !important;
}

.tituloCardZ{
    color: cornflowerblue;
    margin-top: 0.4vmax !important;
    font-weight: bold !important;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tituloCardZ:hover{
    overflow: unset;
    animation: slidein 20s linear;
    -webkit-animation: slidein 20s linear;
}

@keyframes slidein{
    from {right: 0px;}
    to {right: 400px;}
}

.tituloCardG{
    color: cornflowerblue;
    font-weight: bold !important;
}

.fechaReservaCard{
    background-color: lightgray;
    text-align: center !important;
    overflow-x: hidden;
    text-overflow: ellipsis !important;
}

.fechaReservaCard span{
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
}

.fechaReservaCard span:hover{
    animation: slidein 15s linear;
    -webkit-animation: slidein 15s linear;
}

.descripcionReservaCard{
    margin-bottom: 4px !important;

}

.contentButtonReservaCard{
    justify-content: center !important;
}

.sidebar{
    margin: 0;
    border-radius: 0 0 8px;
    box-shadow: 0px 4px 2px -1px rgb(0 0 0 / 20%), 0px 2px 1px 0px rgb(0 0 0 / 14%), 0px 1px 6px 0px rgb(0 0 0 / 12%);
    background-color: whitesmoke;
    display: flex;
    flex-flow: column nowrap;
    width: 18vmax;
    height: 100%;
}

.tituloReservaSidebar{
    margin: 0.8vmax !important;
}

.MuiAccordion-root{
    margin-bottom: 2px;
}

.barraCalendar{
    width: 90%;
}

.botonSidebar{
    padding: 10px;
    box-shadow: -1px 20px 12px -10px rgba(0,0,0,0.38);
}


/*--------------------------------------------------------------------------------------------------

                                                RESPONSIVE

---------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 2560px) and (min-width: 1440px) {
    .iconCardReserva{
        width: 35% !important;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1366px) {
    .MuiGrid-grid-lg-2{
        max-width: 30.666667% !important;
        flex-basis: 30.666667% !important;
    }
    .gridCard {
        margin-bottom: 2%;
    }

}

@media screen and (max-width: 1366px) and (min-width: 1024px) {
    .MuiGrid-grid-lg-2{
        max-width: 24.666667% !important;
        flex-basis: 24.666667% !important;
    }
    .sidebar{
        width: 18.8vmax;
    }

}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .gridCard {
        width: 64.5vmax !important;
    }
    .cardReservas{
        width: 30vmax !important;
    }
    .sidebar{
        width: 20.5vmax;
    }

}

@media screen and (max-width: 768px) and (min-width: 440px) {
    .gridCard {
        width: 70vmax !important;

    }
    .cardReservas{
        width: 30vmax !important;
    }
    .sidebar{
        width: 20.5vmax;

    }
    
}

@media screen and (max-width: 440px) and (min-width: 375px) {
    .containerlooper{
        flex-flow: column nowrap;
    }
    .gridCard {
        margin-top: 2.5%;
        margin-bottom: 10%;
    }
    .sidebar{
        margin: 0;
        width: 100%;
    }

}

@media screen and (max-width: 375px) and (min-width: 320px) {
    .containerlooper{
        flex-flow: column nowrap;
    }
    .gridCard {
        margin-top: 2.5%;
        margin-bottom: 10%;
    }
    .sidebar{
        margin: 0;
        width: 100%;
    }

}

@media (max-width: 320px) {
    .containerlooper{
        flex-flow: column nowrap;
    }
    .gridCard {
        margin-top: 2.5%;
        margin-bottom: 15%;
    }
    .sidebar{
        margin: 0;
        width: 100%;
    }

}

.buttomControl_optUser__28fb0{
    display:none !important;
}

.funkyradio div {
  clear: both;
  overflow: hidden;
}

.funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #D1D3D4;
  font-weight: normal;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="radio"]:empty ~ label,
.funkyradio input[type="checkbox"]:empty ~ label {
  position: relative;
  line-height: 2.5em;
  text-indent: 3.25em;
  margin-top: 0.5em;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.funkyradio input[type="radio"]:empty ~ label:before,
.funkyradio input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 2px 0 0 2px;
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  -ms-border-radius: 2px 0 0 2px;
  -o-border-radius: 2px 0 0 2px;
  padding-right: 0.8em;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #C2C2C2;
}

.funkyradio input[type="radio"]:checked ~ label,
.funkyradio input[type="checkbox"]:checked ~ label {
  color: #777;
}

.funkyradio input[type="radio"]:checked ~ label:before,
.funkyradio input[type="checkbox"]:checked ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #333;
  background-color: #ccc;
}

.funkyradio input[type="radio"]:focus ~ label:before,
.funkyradio input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-default input[type="radio"]:checked ~ label:before,
.funkyradio-default input[type="checkbox"]:checked ~ label:before {
  color: #333;
  background-color: #ccc;
}

.funkyradio-primary input[type="radio"]:checked ~ label:before,
.funkyradio-primary input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #337ab7;
}

.funkyradio-success input[type="radio"]:checked ~ label:before,
.funkyradio-success input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #5cb85c;
}

.funkyradio-danger input[type="radio"]:checked ~ label:before,
.funkyradio-danger input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #d9534f;
}

.funkyradio-warning input[type="radio"]:checked ~ label:before,
.funkyradio-warning input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #f0ad4e;
}

.funkyradio-info input[type="radio"]:checked ~ label:before,
.funkyradio-info input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #5bc0de;
}

/* @import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;700&family=Comfortaa:wght@400;700&display=swap"); */
.text-fix .MuiFormLabel-root .MuiInputLabel-root .MuiInputLabel-formControl .MuiInputLabel-animated .MuiInputLabel-shrink .MuiInputLabel-outlined .MuiFormLabel-filled .Mui-required .Mui-required {
  margin-top: 5px !important;
}

* {
  /* font-family: "Comfortaa", cursive; */
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border: none;
  outline: none;
}

.bg-grad-goph {
  background-image: radial-gradient(circle at 43.84% 120.44%, #1093c6 0, #23688d 25%, #203f54 50%, #131a21 75%, #000000 100%);
}

.bg-goph {
  background: #203f54 !important;
}

.goph_logo {
  font-family: "Baloo 2", cursive;
}
.goph_logo.light {
  text-shadow: 1px 1px 2px gainsboro, 0 0 1em white, 0 0 0.2em white !important;
}

.bg-paleblue {
  background-image: linear-gradient(to top, #3d8bfd 20%, #9ec5fe);
}

.bg-palegreen {
  background-image: linear-gradient(to top, #479f76 20%, #a6e9d5);
}

.bg-lemon {
  background-image: linear-gradient(to top, #75b798 0%, #ffe69c);
}

.bg-lima {
  background-image: linear-gradient(to top, #ffcd39 0%, #a3cfbb);
}

.bg-ocean {
  background-image: linear-gradient(to top, #140330 0%, #0a58ca 70%);
}

.bg-palered {
  background-image: linear-gradient(to top, #e35d6a 20%, #f1aeb5);
}

.bg-peach {
  background-image: linear-gradient(to top, #ea868f 0%, #fecba1 90%);
}

.bg-strongcyan {
  background-image: linear-gradient(to top, #0aa2c0 20%, #0dcaf0);
}

.bg-violetblue {
  background-image: linear-gradient(to top, #ab296a 40%, #3d0a91 100%);
}

.bg-cyan {
  background-image: linear-gradient(to top, #0dcaf0 20%, #0aa2c0);
}

.bg-deepblue {
  background-image: linear-gradient(to top, #160d27 0%, #084298 70%);
}

.home-a {
  overflow-y: auto !important;
  height: 100% !important;
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .home-a {
    height: 40rem !important;
  }
}

.bg-home-goph {
  background-image: url(/static/media/fondo_1.636d8ec3.jpeg);
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100vw 100vh;
  position: relative;
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .bg-home-goph {
    background-image: radial-gradient(circle at 43.84% 120.44%, #1093c6 0, #23688d 25%, #203f54 50%, #131a21 75%, #000000 100%);
  }
}
.bg-home-goph .titulo-element {
  z-index: 1;
}
.bg-home-goph .titulo-element h1 {
  text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.46);
}

.bg-home-goph::before {
  content: "";
  position: absolute;
  background-color: gainsboro;
  opacity: 40%;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .bg-home-goph::before {
    opacity: 0;
  }
}

.bg-home-movile {
  background-color: transparent;
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .bg-home-movile {
    background-image: radial-gradient(circle at 43.84% 120.44%, #1093c6 0, #23688d 25%, #203f54 50%, #131a21 75%, #000000 100%);
  }
}

.footer_2 {
  background-color: #3c5973;
  z-index: 1;
}
.footer_2 span,
.footer_2 h3,
.footer_2 h5,
.footer_2 h6,
.footer_2 p {
  color: white;
}

.logo-goph {
  filter: brightness(100);
  -webkit-filter: brightness(100);
}

.btn-admin button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
  background-color: transparent !important;
  border-color: unset;
  border: 4px solid #0d6efd;
  min-width: 5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-shadow: 2px 3px 4px 0px #adb5bd;
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .btn-admin button {
    width: 12rem;
  }
}
.btn-admin button .nav-drop-title-btn {
  width: 10rem;
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .btn-admin button .nav-drop-title-btn {
    width: 8.5rem;
  }
}
.btn-admin button button,
.btn-admin button [type=button],
.btn-admin button [type=reset],
.btn-admin button [type=submit] {
  -webkit-appearance: button;
          appearance: button;
}
.btn-admin button button:not(:disabled),
.btn-admin button [type=button]:not(:disabled),
.btn-admin button [type=reset]:not(:disabled),
.btn-admin button [type=submit]:not(:disabled) {
  cursor: pointer;
}
.btn-admin button [type=button]:not(:disabled),
.btn-admin button [type=reset]:not(:disabled),
.btn-admin button [type=submit]:not(:disabled),
.btn-admin button button:not(:disabled) {
  cursor: pointer;
}
@media (prefers-reduced-motion: reduce) {
  .btn-admin button {
    transition: none;
  }
}
.btn-admin button::after {
  display: none !important;
}
.btn-admin button:hover,
.btn-admin button:active,
.btn-admin button:focus {
  color: #fff !important;
  background-color: #0a58ca !important;
  box-shadow: 2px 3px 4px 0px #495057;
}

.btn-admin.show > .dropdown-toggle.btn-primary {
  color: #fff !important;
  background-color: #0a58ca;
  box-shadow: 2px 3px 4px 0px #495057;
}

.btn-admin.dark button {
  color: #adb5bd !important;
  border: 4px solid #adb5bd;
  box-shadow: 2px 3px 4px 0px #adb5bd;
}
.btn-admin.dark button:hover,
.btn-admin.dark button:active,
.btn-admin.dark button:focus {
  color: #e9ecef !important;
  border-color: #e9ecef !important;
  background-color: rgba(0, 0, 0, 0.181);
  box-shadow: 2px 3px 4px 0px #6c757d !important;
}

.btn-admin.dark.show .dropdown-toggle.btn-primary {
  color: #6c757d !important;
  border-color: #6c757d !important;
  background-color: rgba(0, 0, 0, 0.181);
  box-shadow: 2px 3px 4px 0px #6c757d !important;
}

.btn-MenuHome {
  border-radius: 50px !important;
  height: -moz-fit-content;
  height: fit-content;
  border: none !important;
  box-shadow: 6px 6px 3px 0px rgba(93, 93, 93, 0.8) !important;
}
.btn-MenuHome .i-mh-container {
  height: 2rem;
  width: 3.1rem;
  position: relative;
}
.btn-MenuHome .i-mh-container .i-MenuHome {
  position: absolute;
  height: 4rem;
  width: 4rem !important;
  left: 0;
  right: 0;
  bottom: 14px;
  top: -9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2rem solid white;
  background-color: grey;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 2;
}
.btn-MenuHome .i-mh-container .i-MenuHome img {
  height: 2.5rem;
  width: 2.5rem;
  filter: brightness(100);
}
.btn-MenuHome .t-mh-container {
  overflow: hidden;
  position: relative;
}
.btn-MenuHome .t-mh-container p,
.btn-MenuHome .t-mh-container h6,
.btn-MenuHome .t-mh-container h5,
.btn-MenuHome .t-mh-container h4,
.btn-MenuHome .t-mh-container h3,
.btn-MenuHome .t-mh-container h2,
.btn-MenuHome .t-mh-container h1 {
  margin-bottom: 0 !important;
}
.btn-MenuHome .t-mh-container:before {
  content: "";
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0.02%, rgba(0, 0, 0, 0));
  width: 29%;
  height: 101%;
  position: absolute;
  transform: rotate(37deg);
  transform-origin: 0 50% 0;
  top: 0;
  left: -0.8rem;
  z-index: 1;
  overflow: hidden;
}
.btn-MenuHome .contador-container {
  position: relative;
  width: 2rem;
}
.btn-MenuHome .contador-container .contador {
  position: absolute;
  background-color: rgb(177, 15, 15);
  height: 2rem;
  width: 2rem;
  right: 0.5rem;
  top: 0.6rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
}

.btn-MenuHome:hover .i-MenuHome {
  transform: scale(1.2);
  transition: transform 0.5s;
}

.card-copy-link {
  border-radius: 50px !important;
  height: -moz-fit-content;
  height: fit-content;
  border: none !important;
  box-shadow: 4px 4px 12px 3px rgba(65, 65, 65, 0.364) !important;
}
.card-copy-link .MuiInputLabel-root {
  left: 6px;
}
.card-copy-link .MuiOutlinedInput-root {
  border-radius: 50px !important;
}
.card-copy-link .MuiInputBase-inputAdornedEnd {
  padding-left: 1rem;
}

.card-description {
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-height: 20rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  animation: fade-out-bck 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes fade-out-bck {
  0% {
    transform: translateZ(0) !important;
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateZ(-80px) !important;
    opacity: 0;
    visibility: hidden;
  }
}
.card-description h3,
.card-description h4,
.card-description h5,
.card-description p,
.card-description span {
  opacity: 0;
  animation: fade-out-bck 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.card-description.show {
  visibility: visible;
  opacity: 1;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fade-in-fwd {
  0% {
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
.card-description.show h3,
.card-description.show h4,
.card-description.show h5,
.card-description.show p,
.card-description.show span {
  opacity: 1;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.card-description.show.slider h3,
.card-description.show.slider h4,
.card-description.show.slider h5,
.card-description.show.slider p,
.card-description.show.slider span {
  animation: slide-bottom 7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s both;
}
@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}

.btn-r-blue {
  color: #fff !important;
  background-color: #5bc0be !important;
  border-color: #5bc0be !important;
}

.btn-r-blue:hover {
  color: #fff !important;
  background-color: #418685 !important;
  border-color: #418685 !important;
}

.btn-r-violet {
  color: #fff !important;
  background-color: #7b5aad !important;
  border-color: #7b5aad !important;
}

.btn-r-violet:hover {
  color: rgba(255, 255, 255, 0.807) !important;
  background-color: #62478b !important;
  border-color: #62478b !important;
}

.buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: nowrap;
  overflow-x: auto;
  min-width: 7rem !important;
}
@media screen and (max-width: 374px) and (min-width: 300px) {
  .buttons-container {
    max-width: 15.5rem !important;
  }
}
@media screen and (max-width: 424px) and (min-width: 375px) {
  .buttons-container {
    max-width: 18.7rem !important;
  }
}
@media screen and (max-width: 767px) and (min-width: 425px) {
  .buttons-container {
    max-width: 100% !important;
  }
}
.buttons-container .col, .buttons-container .col-auto, .buttons-container .col-md-auto {
  margin-right: 0.5rem;
}

@media screen and (max-width: 2580px) and (min-width: 1600px) {
  .txt-asamblea p {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 2580px) and (min-width: 1600px) {
  .txt-asamblea h6 {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 2580px) and (min-width: 1600px) {
  .txt-asamblea h4 {
    font-size: 26px !important;
  }
}

.tituloSize {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 425px) and (min-width: 300px) {
  .tituloSize {
    font-size: 0.7rem;
  }
}

.tituloHome {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .tituloHome {
    font-size: 1.5rem;
  }
}

.ind-txt-xs {
  font-size: 0.9rem;
}

.ind-txt-sm {
  font-size: 1rem;
}

.ind-txt-md {
  font-size: calc(1.255rem + 0.06vw);
}
@media (min-width: 1200px) {
  .ind-txt-md {
    font-size: 1.3rem;
  }
}

.ind-txt {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .ind-txt {
    font-size: 1.5rem;
  }
}

.ind-txt-lg {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .ind-txt-lg {
    font-size: 2rem;
  }
}

.ind-txt-xl {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .ind-txt-xl {
    font-size: 2.5rem;
  }
}

.cover-marqss {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.cover-marqss:hover {
  text-overflow: unset !important;
}

.v_cover_marqss {
  position: relative;
  overflow: hidden !important;
}

.marqss {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.v_marqss {
  position: absolute;
  margin: 0;
}
.v_marqss:hover {
  overflow: unset;
  animation: slideTop 10s linear;
  -webkit-animation: slideTop 10s linear;
}

.marqss:hover {
  overflow: unset;
  animation: slideIn 20s linear;
  -webkit-animation: slideIn 20s linear;
}

@keyframes slideIn {
  from {
    right: 0px;
  }
  to {
    right: 400px;
  }
}
@keyframes slideTop {
  from {
    top: 0%;
  }
  to {
    top: -100%;
  }
}
.g-r-prf1 {
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
}

.g-r-prf2 {
  display: grid;
  grid-template-rows: auto 1fr;
}

.g-r-prf2-1 {
  display: grid;
  grid-template-rows: 1fr auto;
}

.g-r-prf3 {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

.g-r-prf4 {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.g-c-1 {
  display: grid;
  grid-template-columns: 1fr;
}

.g-c-2 {
  display: grid;
  grid-template-columns: auto 1fr;
}

.g-c-3 {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.g-f-c {
  display: grid;
  grid-auto-flow: column;
}

.g-f-d {
  display: grid;
  grid-auto-flow: dense;
}

.m-size-1 {
  width: 60% !important;
  max-width: 60% !important;
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .m-size-1 {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.border-a-1 {
  border: 4px solid !important;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.border-a-1:hover {
  border: 4px dashed !important;
}

.slick-track {
  width: 100vw !important;
  position: absolute;
}

.carrItem .contImgTarjetaCarr1 {
  background-image: radial-gradient(circle at 43.84% 120.44%, #1093c6 0, #23688d 25%, #203f54 50%, #131a21 75%, #000000 100%);
  padding: 1rem 0 1rem 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.carrItem .contImgTarjetaCarr1 .iconoTarjetaCarr {
  max-width: 3rem !important;
}
.carrItem .contImgTarjetaCarr2 .iconoTarjetaCarr {
  max-width: 4 rem !important;
}

.carruselAlpha .slick-list .slick-track {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
  justify-content: start;
}
.carruselAlpha .slick-list .slick-track .slick-slide {
  flex: 0 0 auto;
  width: auto;
}
.carruselAlpha .slick-dots li {
  margin: 0 1rem;
}
.carruselAlpha .slick-dots li button::before {
  font-size: 1rem;
}

.modal-backdrop.show:nth-of-type(1) {
  z-index: 1008;
}

div[role=dialog][aria-modal=true]:nth-of-type(1) {
  z-index: 1014;
}

.offcanvas[role=dialog][aria-modal=true]:nth-of-type(1) {
  z-index: 101410;
}

.modal-backdrop.show:nth-of-type(2) {
  z-index: 1016;
}

div[role=dialog][aria-modal=true]:nth-of-type(2) {
  z-index: 1022;
}

.offcanvas[role=dialog][aria-modal=true]:nth-of-type(2) {
  z-index: 102210;
}

.modal-backdrop.show:nth-of-type(3) {
  z-index: 1024;
}

div[role=dialog][aria-modal=true]:nth-of-type(3) {
  z-index: 1030;
}

.offcanvas[role=dialog][aria-modal=true]:nth-of-type(3) {
  z-index: 103010;
}

.modal-backdrop.show:nth-of-type(4) {
  z-index: 1032;
}

div[role=dialog][aria-modal=true]:nth-of-type(4) {
  z-index: 1038;
}

.offcanvas[role=dialog][aria-modal=true]:nth-of-type(4) {
  z-index: 103810;
}

.modal-backdrop.show:nth-of-type(5) {
  z-index: 1040;
}

div[role=dialog][aria-modal=true]:nth-of-type(5) {
  z-index: 1046;
}

.offcanvas[role=dialog][aria-modal=true]:nth-of-type(5) {
  z-index: 104610;
}

.modal-backdrop.show:nth-of-type(6) {
  z-index: 1048;
}

div[role=dialog][aria-modal=true]:nth-of-type(6) {
  z-index: 1054;
}

.offcanvas[role=dialog][aria-modal=true]:nth-of-type(6) {
  z-index: 105410;
}

.input-number {
  position: relative;
  width: 100px;
  height: 40px;
  background-color: transparent;
  overflow: hidden;
  border-radius: 20px;
  margin: 2px;
}
.input-number span {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  display: inline-block;
  background-color: rgb(89, 89, 89);
  height: 100%;
  width: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #6c6dff;
  letter-spacing: -1px;
}
.input-number input.span {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  display: inline-block;
  background-color: rgb(89, 89, 89);
  height: 100%;
  width: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: white;
  letter-spacing: -1px;
}
.input-number button {
  display: inline-block;
  width: 50px;
  height: 100%;
  background-color: transparent;
  border: none;
  color: rgb(89, 89, 89);
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.2s ease;
}
.input-number button:nth-of-type(1) {
  padding-right: 20px;
}
.input-number button:nth-of-type(2) {
  padding-left: 20px;
}
.input-number button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -webkit-appearance: textfield !important;
          appearance: textfield !important;
}

.input-number-1 {
  background-color: rgb(89, 89, 89) !important;
  height: 80% !important;
  width: 60px !important;
  text-align: center !important;
  line-height: 40px !important;
  font-size: 24px !important;
  color: white !important;
  letter-spacing: -1px;
  min-height: calc(0.5em + 0.2rem + 15px) !important;
  max-height: calc(20em + 1rem + 10px) !important;
}

div[role=presentation].MuiAutocomplete-popper {
  z-index: 5000 !important;
}

.mui-fixer .MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  left: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
}
.mui-fixer .MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
}
.mui-fixer .MuiOutlinedInput-root .MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}
.mui-fixer .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  position: absolute;
  right: 0;
  top: calc(50% - 14px) !important;
}
.mui-fixer .MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.text-slide {
  overflow-x: hidden;
  min-width: 50%;
  position: relative;
  height: 2rem;
  width: 100%;
}
.text-slide h1,
.text-slide h2,
.text-slide h3,
.text-slide h4,
.text-slide h5,
.text-slide h6,
.text-slide span,
.text-slide p,
.text-slide a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  left: 0;
  top: 7%;
}
.text-slide h1:hover,
.text-slide h2:hover,
.text-slide h3:hover,
.text-slide h4:hover,
.text-slide h5:hover,
.text-slide h6:hover,
.text-slide span:hover,
.text-slide p:hover,
.text-slide a:hover {
  overflow-x: unset;
  animation: slide 1.5s linear both;
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.MuiMenu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.snack-b-container {
  height: 4rem;
  padding-right: 0.5rem;
}
.snack-b-container .snack-b {
  position: absolute;
  border-radius: 5px;
  height: auto;
  left: 2px;
  z-index: 10;
}
.snack-b-container .snack-b button {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}
.snack-b-container .snack-b button:hover svg {
  animation: rotate-center 0.6s ease-in-out both;
}
@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 426px) and (min-width: 300px) {
  .snack-b-container .snack-b button {
    width: 2rem !important;
    height: 2rem !important;
  }
}
.snack-b-container .snack-b.close {
  animation: scale-out-hor-left 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@keyframes scale-out-hor-left {
  0% {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: scaleX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}

.bg-blue-sky {
  background-color: #FCF3CF !important;
}
.bg-blue-sky h1, .bg-blue-sky h2, .bg-blue-sky h3, .bg-blue-sky h4, .bg-blue-sky h5, .bg-blue-sky h6, .bg-blue-sky p, .bg-blue-sky span, .bg-blue-sky svg {
  color: #495057;
}
.bg-blue-sky.bold {
  background-color: #ffcd39 !important;
}
.bg-blue-sky.bold h1, .bg-blue-sky.bold h2, .bg-blue-sky.bold h3, .bg-blue-sky.bold h4, .bg-blue-sky.bold h5, .bg-blue-sky.bold h6, .bg-blue-sky.bold p, .bg-blue-sky.bold span, .bg-blue-sky.bold svg {
  color: #495057;
}
.bg-blue-sky.bold:hover {
  background-color: #cc9a06 !important;
}
.bg-blue-sky.bold:hover h1, .bg-blue-sky.bold:hover h2, .bg-blue-sky.bold:hover h3, .bg-blue-sky.bold:hover h4, .bg-blue-sky.bold:hover h5, .bg-blue-sky.bold:hover h6, .bg-blue-sky.bold:hover p, .bg-blue-sky.bold:hover span, .bg-blue-sky.bold:hover svg {
  color: #e9ecef;
}

.bg-rose {
  background-color: #D5F5E3 !important;
}
.bg-rose h1, .bg-rose h2, .bg-rose h3, .bg-rose h4, .bg-rose h5, .bg-rose h6, .bg-rose p, .bg-rose span, .bg-rose svg {
  color: #495057;
}
.bg-rose.bold {
  background-color: #198754 !important;
}
.bg-rose.bold h1, .bg-rose.bold h2, .bg-rose.bold h3, .bg-rose.bold h4, .bg-rose.bold h5, .bg-rose.bold h6, .bg-rose.bold p, .bg-rose.bold span, .bg-rose.bold svg {
  color: #f8f9fa;
}
.bg-rose.bold:hover {
  background-color: #0f5132 !important;
}

.bg-most {
  background-color: #D6EAF8 !important;
}
.bg-most h1, .bg-most h2, .bg-most h3, .bg-most h4, .bg-most h5, .bg-most h6, .bg-most p, .bg-most span, .bg-most svg {
  color: #495057;
}
.bg-most.bold {
  background-color: #6ea8fe !important;
}
.bg-most.bold:hover {
  background-color: #0d6efd !important;
}
.bg-most.bold:hover h1, .bg-most.bold:hover h2, .bg-most.bold:hover h3, .bg-most.bold:hover h4, .bg-most.bold:hover h5, .bg-most.bold:hover h6, .bg-most.bold:hover p, .bg-most.bold:hover span, .bg-most.bold:hover svg {
  color: #dee2e6;
}

.sidebar-reserva {
  height: 5rem;
}
.sidebar-reserva .s-r-option {
  width: 100%;
  height: 36.5px;
}
.sidebar-reserva .s-r-option .absolute-swt {
  position: absolute !important;
  top: 0;
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .sidebar-reserva .s-r-option .absolute-swt {
    position: static !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 300px) {
  .sidebar-reserva {
    height: 15rem;
    min-height: 2.5rem;
  }
}

.nav_report_main {
  background-color: whitesmoke;
}
.nav_report_main ul {
  padding: 0;
}
.nav_report_main ul li {
  display: flex;
  justify-content: center;
  grid-column-gap: 0.5em;
  column-gap: 0.5em;
  align-items: center;
  width: 100%;
  color: dimgray;
  font-size: 1.5em;
}
.nav_report_main ul li.active {
  color: lightgray;
}
.nav_report_main ul li:hover {
  color: darkturquoise;
}

.img_card_menu {
  width: 100%;
  height: 100%;
  max-height: 233px;
  overflow: hidden;
  border-radius: 0.3rem 0.3rem 0 0;
}
.img_card_menu path::after {
  fill: aquamarine;
}
.img_card_menu path::before {
  fill: aquamarine;
}
.img_card_menu image.image_svg {
  background-repeat: no-repeat;
  background-color: red !important;
}

.card_menu_home {
  transition: all 0.2s ease-in-out;
}
.card_menu_home:hover {
  transform: scale(1.1);
}

.overflow_card_menu {
  overflow-x: unset;
}
@media (max-width: 820px) {
  .overflow_card_menu {
    overflow-x: auto;
  }
}


.custom-dropdown-toggle::after {
  display: none !important;
}
/*# sourceMappingURL=styles.css.map */

.custom-scroll {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f5f5f5;
  border-radius: 10px; /* Agrega esquinas redondeadas */
}

.custom-scroll::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 8px; /* Esquinas redondeadas para el thumb */
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 8px; /* Esquinas redondeadas para el track */
}

.custom-textfield {
  font-family: Arial;
  font-size: 14px;
  height: 24px;
}

select.minimal {
  width: 100%;
  margin-top: 3px;
  background-color: white;
  border: thin solid #b8daff;
  border-radius: 4px;
  display: inline-block;
  font-size: 13px;
  line-height: 1.5em;
  padding: 0.9em 1em;
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: none;

  background-image:
    linear-gradient(45deg, blue 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, blue 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1.5em,
    calc(100% - 20px) 1.5em,
    calc(100% - 2.5em) 1em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: #b8daff;
  outline: 0;
}

select.minimal:focus {
  border-color: blue;
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.size-95{
  width: 98% !important;
}

.texto_x{
  font-size: 12px;
}

