* {
    box-sizing: border-box;
}

body {
    font-family: 'Source Sans 3', sans-serif;
}

.sidebar-nh {
    border-right: 1px solid #e9ecf2;
    box-shadow: 1px 0px #f8f9fa;
    height:100%;
    background-color: #f9f9f9 !important;
}

.sidebar-nh a {
    padding: 10px 10px 10px 20px;
    text-decoration: none;
    font-size: 18px;
    color: #333;
    display: block;
}

.sidebar-nh p {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.sidebar-nh a:hover {
    background-color: rgba(224, 229, 247, 0.7);
    border-radius: 10px;
    margin-right: 10px;
}

.main-content {
    margin-left: -15px !important;
}

.card-custom {
    margin-bottom: 10px;
    line-height: 0;
}

.card-custom.sol{
    min-height: 125px;
    max-height: 125px;
    border-radius: 12px;
    background-color: rgba(224, 229, 247, 0.7);
    color: #fff;
}

.card-custom.menu{
    min-height: 135px;
    max-height: 135px;
    overflow: hidden;
    background-color: white;
    border-radius: 12px;
}

.card-custom.menu i{
    font-size: 24px;
}

.card-custom.img{
    background-color: transparent;
    border: none;
}

.card-title{
    font-weight:lighter;
}

.card-menu-text{
    font-weight: 400;
    color:#787474;
    font-size: 15px;
}

.min-size-sol{
    padding: 7px;
    font-size: 18px;
    border-radius: 12px;
    background-color: rgba(224, 229, 247, 0.7);
    color: #fff;
}

.navbar-custom {
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
}

.navbar-custom .navbar-brand, .navbar-custom .nav-link {
    color: #333;
}

h1.title {
    font-family: "Arial";
    font-size: 20px;
}

.texto-resp {
    font-size: calc(0.8em + 0.2vw);
    line-height: 1.1em
}

.welcome{
    min-height: 200px;
    max-height: 200px;
    border-radius: 14px;
}

.text-content {
    margin-left: 12px;
    line-height: 1.2;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
    font-size: 16px;
}

.footer {
    background-color:#212529;
    padding: 20px 0;
    color:#ced4da;
}

.icon-text-container {
    display: flex;
    align-items: center;
}
.icon-circle {
    display: flex;
    margin-left: 50px;
}

.bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}


@media (max-width: 767.98px) {

    .main-content {
        min-width: calc(100% - 30px);
        margin-left: 15px !important;
    }

    .texto-resp {
        font-size: 1em;
    }

    .card-menu-text {
        padding-top: 5px;
    }

    .text-left-footer {
        text-align: center;
        margin-bottom: 1rem;
    }
}