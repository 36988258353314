

a{
    text-decoration: none !important;
}

a:active{
    text-decoration: none !important;
    border: none !important;
}

body{
	width: 100%;
    /*background-image: radial-gradient(circle at 43.84% 120.44%, #1093c6 0, #23688d 25%, #203f54 50%, #131a21 75%, #000000 100%) !important;*/
}

.contenedorPadre{
    margin-top: 5%;
    margin-bottom: 5%;
}

.contenedorPadre2 {
    /* width: 100%; */
    /* display: flex; */
    justify-content: space-evenly;
    align-items: center;
    /* flex-wrap: nowrap; */
    /* flex-direction: row; */
}

.ro

.contenedorPadreTabla{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 3vh;
}

.contenedorUploader{
    background-color: #E9EEF2;
    padding: 3% 0 3% 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.contAccionesUploader{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #8C8C7D;
    border-radius: 20px;
    padding: 6%;
    width: 75%;
    height: 100%;
    margin-bottom: 3%;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
}

.contAccionesUploader:hover{
    background-color: #8c8c7d9f;
    border: 4px dashed #8C8C7D;
}

.contMensajeUploader{
    background-color:#8C8C7D;
    width: 50%;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    color: #E9EEF2;
}

#drop-area{
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.spanO{
    font-weight: bold;
    font-size: 1.5vw;
    padding-bottom: 10px;
    margin-bottom: 8px;
}

.contenedorInfoDoc{
    overflow: hidden;
}

/* .contenedorInfoDoc span, p{
    font-weight: bold !important;
} */

.contenedorInfoDoc span{
    color: #0A5DA6;
    font-size: 1.2vw;
}

.tituloDoc{
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tituloDoc:hover{
    overflow: unset;
    animation: slidein2 20s linear !important;
}

@keyframes slidein2{
    from {right: 0px;}
    to {right: 800px;}
}

.botonRevisarArch{
    display:flex !important;
    height: 5vh !important;
    font-size: large !important;
    white-space: nowrap !important;
}

.LinkBRevisarA{
    margin-right: 10% !important;
}

.bRAIcon{
    margin-left: 5px;
    padding-bottom: 2.5px;
}

/*--------------------------------------- TABLA ARCHIVOS ---------------------------------------------*/

.contenedorTablaArch{
    background-color: #E9EEF2;
    width: 100%;
    margin: auto;
    border-radius: 5px !important;
}

.tituloTablaArch{
    text-align:center;
    color: #E9EEF2;
    font-size: xx-large;
}

.table-dark-arch{
    background-color: #8C8C7D;
}

.cBElminar{
    width:100% !important;
}

.botonVolverUpl{
    display:flex !important;
    margin-right: 2% !important;
    height: 5vh !important;
    font-size: large !important;
    white-space: nowrap !important;
}

.bRAIcon2{
    margin-right: 5px;
}

/*----------------------------------------------------------------------------------------------------

                                            RESPONSIVE

-----------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 2580px) and (min-width: 1440px) {


}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
    .botonVolverUpl{
        margin-left: 2% !important;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .contenedorPadre2{
        flex-direction: column !important;
    }
    
    .contenedorUploader{
        margin-bottom: 2% !important;
    }

    .botonVolverUpl{
        margin-top: 2% !important;
    }

}

@media screen and (max-width: 768px) and (min-width: 440px) {

    .botonRevisarArch{
        display:flex !important;
        margin-right: 0 !important;
    }

    .contenedorUploader{
        margin-top: 10% !important;
        margin-bottom: 12%;
    }

    .contenedorInfoDoc span{
        color: #0A5DA6;
        font-size: 2.2vw;
    }

    .contAccionesUploader{
        padding: 0;
        padding-top: 1%;
        border: none !important;
        background-color: transparent !important;
    }

    .contAccionesUploader h3, .spanO, #drop-area{
        display: none;
    }

}

@media screen and (max-width: 440px) and (min-width: 320px) {

    .botonRevisarArch{
        display:flex !important;
        margin-right: 0 !important;
    }

    .contenedorUploader{
        margin-top: 20% !important;
        margin-bottom: 18%;
    }

    .contenedorInfoDoc span{
        color: #0A5DA6;
        font-size: 5vw;
    }

    .contAccionesUploader{
        padding: 0;
        padding-top: 1%;
        border: none !important;
        background-color: transparent !important;
    }

    .contAccionesUploader h3, .spanO, #drop-area{
        display: none;
    }

    .botonVolverUpl{
        position: sticky !important;
    }

}

@media (max-width: 320px) {

    .contenedorPadre2{
        flex-direction: column !important;
        overflow-x: auto;
        padding-left: 155vw;
        padding-right: 155vw !important;
    }

    .botonRevisarArch{
        display:flex !important;
        margin-right: 0 !important;
    }

    .contenedorUploader{
        margin-top: 20% !important;
        margin-bottom: 18%;
    }

    .contenedorInfoDoc span{
        color: #0A5DA6;
        font-size: 5vw;
    }

    .contAccionesUploader{
        padding: 0;
        padding-top: 1%;
        border: none !important;
        background-color: transparent !important;
    }

    .contAccionesUploader h3, .spanO, #drop-area{
        display: none;
    }
    .contenedorPadreTabla{
        margin-left: 60vw;
        margin-right: 68vw;
    }
}