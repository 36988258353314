.cover{
    text-overflow: ellipsis;
}

.cover:hover{
    text-overflow: unset;
}

.titulo{
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.titulo:hover{
    overflow: unset;
    animation: slideIn 20s linear;
    -webkit-animation: slideIn 20s linear;
}

@keyframes slideIn{
    from {right: 0px;}
    to {right: 400px;}
}

.txt_orange{
    color: chocolate !important;
}