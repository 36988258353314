/*------------------------------------------------------------------ 
    IMPORT FILES 
-------------------------------------------------------------------*/

@import url(https://use.fontawesome.com/releases/v5.0.6/css/all.css);

/*------------------------------------------------------------------ 
    SKELETON
-------------------------------------------------------------------*/
/*navbar--------------------------------------------------------------------------------------*/

.brand-goph {
  margin-left: 5%;
}

.navbar-nav {
  justify-content: center;
}

#navbarNavDropdown2 {
  justify-content: center;
}

.item-adm {
  color: #fff;
}

.togglerBtn {
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.nav-logo{
  width: 15%;
}

#ph-name {
  font-size: 17px;
  color: white;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}

.nav-username {
  position: absolute;
  width: 15%;
}

.far-icon{
  margin: 4px;
}

.rojo{
  color: red;
}

.card-size3{
  height: 106px;
  width: 106px;
}

.icon-btn-user{
  margin-top: 4px;
}

#logo-header{
  width: 12%;
}

.nav-header{
  vertical-align: middle;
}

.card-goph{
  width: 17rem;
}

.icon-menu-home{
  height: 30%;
  width: 30%;
  padding: 5px;
}

.home-card{
  padding: 20px 80px;
}

.purple{
  background-color: #6f42c1;
}

.pink{
  background-color: #d63384;
}

.container123{
  padding: 0 140px;
  background-color: #f8f9fa;
}

.row-header {
  padding-top: 20px;
}

.total-page{
  height: 110vh;
}

.Active{
  border-bottom: 2px solid white !important;
}

.Active2{
  border-bottom: 2px solid gray !important;
}

.titulo{
  font-family: 'Enriqueta', arial, serif;
  line-height: 1.25;
  margin: 0 0 10px;
  font-size: 32px;
  color: #333;
  font-weight: bold;
}

.titulo1{
  font-family: 'poppins', arial;
  font-size: 22px;
  color: #333;
  font-weight: bold;
}

.texto-descriptivo{
  font-family: 'Enriqueta', arial, serif;
  line-height: 1.25;
  margin: 0 0 10px;
  font-size: 14px;
  color: #333;
}

.cursiva-negrilla{
  font-style: italic;
  font-weight: bold;
}

.data-label{
  margin: 5px !important;
}

.c-10{
  padding: 0 12% !important;
}

.sub-page{
  height: 100vh;
  background-color: #f8f9fa;
}

.interline1{
  line-height: 1;
}

.fa-blue{
  color: #007bff;
}

.mt-05{
  margin-top: 5;
  margin-bottom: 0;
}

.mb-05{
  margin-bottom: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.fixed-top{
  z-index:100;
}

.titulo .tituloUploader{
  font-size: 24px;
}



/*--------------------------------------------MENU ADMIN--------------------------------------------*/

.cardAdm {
  padding: 20px;
  border: none;
  text-align: center;
}

.contCard {
  padding: 40px 0;
}

.container12 {
  text-align: center;
  margin-top: 1.5em;
}

.container1 a {
  text-decoration: none;
  text-decoration-color: initial;
  margin: 0em;
}

.btnNBcc {
  display: inline-flex;
  margin-bottom: 0;
  margin-top: 2px;
  color: #cccccc;
  font-size: 15px;
  font-weight: 400;
}
.btnNBcc i {
  float: left;
  padding-top: 0.5vmin;
  padding-right: 0.5vmin;
}

p.btnNBcc {
  float: left;
  width: 8vmax;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.countAdm {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background:  	#dc3545;
  position: absolute;
  top: 3%;
  margin-left: 60%;
}

.centered {
  position: absolute;
  font-weight: bold;
  font-size: 0.8rem;
  color: #f8f9fa;
  left: 0;
  text-align: center;
  top: 5px;
  width: 100%
}

/* .MuiPaper-root {
  margin-bottom: 50px;
} */

.BtnForm-back {
  padding-top: 5vmax;
  margin-left: 15vmax;
}

.iconoMenuHome {
  transition: 0.3s;
  height: 150px;
  width: 150px;
  position: relative;
}

.iconoMenuHome:hover {
  transform: scale(1.1);
}

.donita{
  height: 85vh !important;
  margin-top: 90px;
}

.spinner-border{
  width: 4rem;
  height: 4rem;
  border: 0.30em solid currentColor;
}

.tooltipHome{
   width: 100%;
}

.MuiTableRow-footer{
  overflow: auto !important;
}

/*------------------------------------------------------------------------------------------------------------

                                  RESPONSIVE

--------------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 2580px) and (min-width: 1440px) {
  .cardAdm2 {
    height: 300px;
    margin-left: 9vmin;
    margin-right: 9vmin;
    margin-bottom: 2vmax;
  }

  .item-adm {
    display: none;
  }

  .contCard {
    height: 85vmax;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1366px) {
  .cardAdm2 {
    font-size: 2.5vmin;
    height: 15vmax;
    margin-left: 15vmin;
    margin-bottom: 1vmax;
  }
  .item-adm {
    display: none;
  }

  .container1 {
    margin-top: 2vmax;
  }

  .contCard {
    height: 175vmin;
  }

  .btnNBcc {
    width: 11vmin;
  }
}

@media screen and (max-width: 1365px) and (min-width: 1024px) {
  .cardAdm2 {
    font-size: 2.5vmin;
    height: 15vmax;
    margin-left: 15vmin;
    margin-bottom: 1vmax;
  }

  .item-adm {
    display: none;
  }

  .container1 {
    margin-top: 2vmax;
  }

  .contCard {
    height: 175vmin;
  }

  .btnNBcc {
    width: 11vmin;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 61%;
  }
}

@media screen and (max-width: 1199px) and (min-width: 300px) {
  .navbarBtnAdm2 {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) and (min-width: 620px) {
  .cardAdm2 {
    font-size: 2.5vmin;
    height: 15vmax;
    margin-left: 11vmin;
    margin-bottom: 1vmax;
  }


  .btnNBcc {
    margin-top: 5px;
    width: max-content !important;
  }
  .contCard {
    height: 110vmax;
  }

  .container1 {
    margin-top: 1vmax;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 64%;
  }
}

/* @media screen and (max-width: 719px) and (min-width: 540px) {
  
  .cardAdm {
    margin: 0 30px;
  }
} */

@media screen and (max-width: 619px) and (min-width: 441px) {
  .cardAdm2 {
    font-size: 0.9rem;
    height: 15vmax;
    margin-left: 11vmin;
    margin-bottom: 1vmax;
  }

  .btnNBcc {
    margin-top: 0px;
    width: max-content !important;
    font-size: 0;
  }

  .btnNBcc::after {
    font-family: "Font Awesome 5 Free";
    font-size: 1.8vmax;
    content: "\f007";
    text-align: left;
  }

  .container1 {
    margin-top: 2vmax;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 63%;
  }
}

@media screen and (max-width: 440px) and (min-width: 300px) {
  .cardAdm2 {
    font-size: 0.8rem;
    height: 120px;
    margin-left: 8vmin;
    margin-bottom: 2vmax;
  }
  
  #ph-name {
    display: none;
  }

  .btnNBcc {
    font-size: 0;
    width: max-content !important;
  }

  .btnNBcc::after {
    font-family: "Font Awesome 5 Free";
    font-size: 1.8vmax;
    content: "\f007";
    text-align: left;
  }

  .total-page{
    height: 120vh !important;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 67%;
  }
}

@media (max-width: 300px) {
  .cardAdm2 {
    font-size: 0.7rem;
    height: 120px;
    margin-bottom: 2vmax;
  }

  .btnNBcc {
    font-size: 0;
  }

  .btnNBcc::after {
    font-family: "Font Awesome 5 Free";
    font-size: 1.8vmax;
    content: "\f007";
    text-align: left;
  }

  /* BOTON ROJO CONTADOR INMUEBLES Y RESERVAS POR APROBAR */
  .countAdm {
    margin-left: 61%;
  }

  .total-page{
    height: 120vh !important;
  }

}
