
.containerlooper{
/*background-color: lightgray;*/
    display: flex;

    margin-bottom: 50px;
}

.gridCard{
    float: left;
    margin-bottom: 5%;
}

.cardReservas{
    margin: 2vmax !important;
    border-radius: 10px !important;
    box-shadow: 13px 12px 12px -2px rgba(0,0,0,0.38);
}

.cardReservas ::-webkit-scrollbar{
    display: none;
}

.tituloACardZ{
    width: 100%;
    height: 38px;
    margin-bottom: 0.4vmax !important;
    overflow-x: hidden;
    
}

.contImgTarjetaCarr{
    background-image: radial-gradient(circle at 43.84% 120.44%, #1093c6 0, #23688d 25%, #203f54 50%, #131a21 75%, #000000 100%);
}

.iconCardReserva{
    width: 50% !important;
}

.tituloCardZ{
    color: cornflowerblue;
    margin-top: 0.4vmax !important;
    font-weight: bold !important;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tituloCardZ:hover{
    overflow: unset;
    animation: slidein 20s linear;
    -webkit-animation: slidein 20s linear;
}

@keyframes slidein{
    from {right: 0px;}
    to {right: 400px;}
}

.tituloCardG{
    color: cornflowerblue;
    font-weight: bold !important;
}

.fechaReservaCard{
    background-color: lightgray;
    text-align: center !important;
    overflow-x: hidden;
    text-overflow: ellipsis !important;
}

.fechaReservaCard span{
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
}

.fechaReservaCard span:hover{
    animation: slidein 15s linear;
    -webkit-animation: slidein 15s linear;
}

.descripcionReservaCard{
    margin-bottom: 4px !important;

}

.contentButtonReservaCard{
    justify-content: center !important;
}

.sidebar{
    margin: 0;
    border-radius: 0 0 8px;
    box-shadow: 0px 4px 2px -1px rgb(0 0 0 / 20%), 0px 2px 1px 0px rgb(0 0 0 / 14%), 0px 1px 6px 0px rgb(0 0 0 / 12%);
    background-color: whitesmoke;
    display: flex;
    flex-flow: column nowrap;
    width: 18vmax;
    height: 100%;
}

.tituloReservaSidebar{
    margin: 0.8vmax !important;
}

.MuiAccordion-root{
    margin-bottom: 2px;
}

.barraCalendar{
    width: 90%;
}

.botonSidebar{
    padding: 10px;
    box-shadow: -1px 20px 12px -10px rgba(0,0,0,0.38);
}


/*--------------------------------------------------------------------------------------------------

                                                RESPONSIVE

---------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 2560px) and (min-width: 1440px) {
    .iconCardReserva{
        width: 35% !important;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1366px) {
    .MuiGrid-grid-lg-2{
        max-width: 30.666667% !important;
        flex-basis: 30.666667% !important;
    }
    .gridCard {
        margin-bottom: 2%;
    }

}

@media screen and (max-width: 1366px) and (min-width: 1024px) {
    .MuiGrid-grid-lg-2{
        max-width: 24.666667% !important;
        flex-basis: 24.666667% !important;
    }
    .sidebar{
        width: 18.8vmax;
    }

}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .gridCard {
        width: 64.5vmax !important;
    }
    .cardReservas{
        width: 30vmax !important;
    }
    .sidebar{
        width: 20.5vmax;
    }

}

@media screen and (max-width: 768px) and (min-width: 440px) {
    .gridCard {
        width: 70vmax !important;

    }
    .cardReservas{
        width: 30vmax !important;
    }
    .sidebar{
        width: 20.5vmax;

    }
    
}

@media screen and (max-width: 440px) and (min-width: 375px) {
    .containerlooper{
        flex-flow: column nowrap;
    }
    .gridCard {
        margin-top: 2.5%;
        margin-bottom: 10%;
    }
    .sidebar{
        margin: 0;
        width: 100%;
    }

}

@media screen and (max-width: 375px) and (min-width: 320px) {
    .containerlooper{
        flex-flow: column nowrap;
    }
    .gridCard {
        margin-top: 2.5%;
        margin-bottom: 10%;
    }
    .sidebar{
        margin: 0;
        width: 100%;
    }

}

@media (max-width: 320px) {
    .containerlooper{
        flex-flow: column nowrap;
    }
    .gridCard {
        margin-top: 2.5%;
        margin-bottom: 15%;
    }
    .sidebar{
        margin: 0;
        width: 100%;
    }

}
